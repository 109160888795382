<script>
import {
  globalGetters,
} from "@/state/helpers";

export default {
  props: {
      stats: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  computed:{
    ...globalGetters
  },
  data() {
    return {
    };
  },
};
</script>

<template>
  <b-skeleton-wrapper :loading="loader">
    <template #loading>
    <b-row class="mb-4">
      <b-col>
        <b-skeleton-img no-aspect height="150px" class="skeleton-img"></b-skeleton-img>
      </b-col>
      <b-col>
        <b-skeleton-img no-aspect height="150px" class="skeleton-img"></b-skeleton-img>
      </b-col>
      <b-col>
        <b-skeleton-img no-aspect height="150px" class="skeleton-img"></b-skeleton-img>
      </b-col>
      <b-col>
        <b-skeleton-img no-aspect height="150px" class="skeleton-img"></b-skeleton-img>
      </b-col>
    </b-row>
  </template>
  <div class="row">
    <div class="col-md-3" v-for="(data, index) in stats" :key="index">
      <router-link :to="data.url">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2 text-dark">{{data.title}}</p>
              <h4 class="mb-0">{{data.value}}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>

        <div class="card-body border-top py-3">
          <div class="text-truncate">
            <!-- <span class="badge badge-soft-success font-size-11">
              <i class="mdi mdi-menu-up"></i>
              {{data.subvalue}}
            </span> -->
            <span class="text-muted ml-2">From previous period</span>
          </div>
        </div>
      </div>
    </router-link>
    </div>
  </div>
</b-skeleton-wrapper>
  <!-- end row -->
</template>

<style>
.skeleton-img{
  border-radius: 4px;overflow: hidden;
}
</style>