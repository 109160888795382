<script>
/**
 * Revenue Analytics component
 */
 import {
  dashboardMethods,
  dashboardGetters
} from "@/state/helpers";
export default {
  created(){
    this.setYearHistory();
  },
  data() {
    return {
      showAna: false,
      activeEle:'yearly',
      loader:false,
      chartOptions: {
        chart: {
          id: 'vuechart-example',
          height: 280,
          type: "line",
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: [0, 3],
          curve: "smooth"
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%"
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        colors: ["#5664d2", "#1cbb8c"],
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ]
      }
    };
  },
  mounted() {
},
computed:{
  ...dashboardGetters
},
methods: {
  ...dashboardMethods,
  todayChart(){
    this.loader = true;
    this.setTodayOrdersHistory();
    this.chartOptions = {
      ...this.chartOptions,
        labels: ["12 AM","1 AM","2 AM","3 AM","4 AM","5 AM","6 AM","7 AM","8 AM","9 AM","10 AM","11 AM","12 PM","1 PM","2 PM","3 PM","4 PM","5 PM","6 PM","7 PM","8 PM","9 PM","10 PM","11 PM",]
      }
      this.activeEle = 'today'
      this.loader = false
  },
  mothlyChart(){
    this.loader = true;
    this.chartOptions = { ...this.chartOptions,labels: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]}
    this.setMonthlyOrdersHistory();
    this.activeEle = 'monthly';
    this.loader = false;
  },
  yearlyChart(){
    this.loader = true;
    this.chartOptions = { ...this.chartOptions,labels: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]}
    this.setYearHistory();
    this.activeEle = 'yearly'
    this.loader = false;
  }
}
};
</script>

<template>
  <div class="card">

    <div class="card-body" >
      <div class="float-right d-none d-md-inline-block">
        <div class="btn-group mb-2">
          <button type="button" @click="todayChart" class="btn btn-sm btn-light" :class="activeEle == 'today' ? 'active' : ''">Today</button>
          <button type="button" @click="yearlyChart" class="btn btn-sm btn-light" :class="activeEle == 'yearly' ? 'active' : ''">Yearly</button>
          <button type="button" @click="mothlyChart" class="btn btn-sm btn-light" :class="activeEle == 'monthly' ? 'active' : ''">Monthly</button>
        </div>
      </div>
      <h4 class="card-title mb-4">Analytics</h4>
      <div>
        <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
        <apexchart 
          class="apex-charts"
          height="280"
          ref="demoChart"
          dir="ltr"
          :series="newSeries"
          :options="chartOptions"
        ></apexchart>
      </div>
    </div>

    <div class="card-body border-top text-center">
      <div class="row">
        <div class="col-sm-4">

          <div class="mt-4 mt-sm-0">
            <div class="d-inline-flex">
              <h5 class="mb-0 mr-2">{{currentMonthOrders}}</h5>
              <!-- <div class="text-success">
                <i class="mdi mdi-menu-up font-size-14"></i>2.1 %
              </div> -->
            </div>
            <p class="mb-2 text-muted text-truncate">
              <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i> This month :
            </p>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="mt-4 mt-sm-0">
            <div class="d-inline-flex">
              <h5 class="mb-0 mr-2">{{currentYearOrders}}</h5>
              <!-- <div class="text-success">
                <i class="mdi mdi-menu-up font-size-14"></i>2.1 %
              </div> -->
            </div>
            <p class="mb-2 text-muted text-truncate">
              <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i> This Year :
            </p>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="mt-4 mt-sm-0">
            <div class="d-inline-flex">
              <h5 class="mb-0"> {{ previousYearOrders }}</h5>
            </div>
            <p class="mb-2 text-muted text-truncate">
              <i class="mdi mdi-circle text-success font-size-10 mr-1"></i> Previous Year :
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>