<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {
  dashboardMethods,
  dashboardGetters
} from "@/state/helpers";

import Stat from "./widget";
import RevenueAnalytics from "./revenue";
import Pusher from 'pusher-js'
// import SalesAnalytics from "./sales-analytics";
// import EarningReport from "./earning";
// import Sources from "./sources";
// import RecentActivity from "./recent-activity";
// import RevenueLocation from "./revenue-location";
// import Chat from "./chat";
// import Transaction from './transaction';

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
     Stat,
    RevenueAnalytics,
    // SalesAnalytics,
    // EarningReport,
    // Sources,
    // RecentActivity,
    // RevenueLocation,
    // Chat,
    // Transaction
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Home"
        },
        {
          text: "Dashboard",
          active: true
        }
      ]
    };
  },
  created(){
  },
  beforeRouteEnter(to, from, next){
    next(vm => {
      let daschBoardData = vm.$store.state.dashboard.dashboardDetails;
      if(Object.keys(daschBoardData).length === 0){
        vm.$store.dispatch('dashboard/setDashboardData')}
       }
      );
  },
  computed: {
    ...dashboardGetters,
  },
  methods:{
    ...dashboardMethods,
    subscribe () {
      const pusher = new Pusher('c69299e1f0e5ea9b19d4', {
        cluster: 'ap2',
        channelAuthorization: {
          endpoint: '${process.env.VUE_APP_BASE_URL}/wp-json/pusher_sockets/v1/auth?user=1'
        },
      });

      pusher.subscribe('private-website_1')
      pusher.bind('redirections', data => {
        console.log(data);
      //   this.$swal({
      //   toast: true,
      //   icon: 'success',
      //   title: data.message,
      //   position: 'top-end',
      //   showConfirmButton: false,
      //   timer: 3000,
      //   timerProgressBar: true,
      //   didOpen: (toast) => {
      //     toast.addEventListener('mouseenter', this.$swal.stopTimer)
      //     toast.addEventListener('mouseleave', this.$swal.resumeTimer)
      //   }
      // });  
      })
    },
    makeToast(append = false) {
      if(append == false){
        return;
      }
      this.$swal({
        toast: true,
        icon: 'success',
        title: 'Signed in successfully',
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', this.$swal.stopTimer)
          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        }
      });  
    }
  },
  mounted(){
     this.makeToast(this.$route.params.loggedIn);
     this.subscribe()
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <Stat :stats="getData.stats" />
        <RevenueAnalytics/>
      </div>
      <!-- <div class="col-xl-4">
        <SalesAnalytics />
        <EarningReport />
      </div> -->
    </div>
    <div class="row">
      <div class="col-lg-4">
        <!-- <Sources /> -->
      </div>
      <div class="col-lg-4">
        <!-- <RecentActivity /> -->
      </div>
      <div class="col-lg-4">
        <!-- <RevenueLocation /> -->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <!-- <Chat /> -->
      </div>
      <div class="col-lg-8">
        <!-- <Transaction /> -->
      </div>
    </div>
  </Layout>
</template>
<style>
.swal2-popup{
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
}
</style>